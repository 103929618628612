<template>
  <div style="height: 100%">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  outline-style: none;
}
html {
  line-height: 26px;
  font-size: 14px;
}
html,body,#app {
  height: 100%;
}
input{
  outline: none;
}
ul, ol, ul, li, ol, dl, dd, dt {
  list-style: none;
}
::-webkit-scrollbar { display: none; }
button {
  user-select: none;
}
</style>
